<
<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="formatedData()"
      :pagination-options="{
        enabled: true,
      }"
      :theme="isDarkMode()"
    >
      <div slot="table-actions" class="table-actions-container">
        <CInput label="Start Date" type="date" v-model="selectedDate.start" />
        <CInput
          label="Stop Date"
          type="date"
          class="stop-date"
          v-model="selectedDate.end"
        />
        <CInput
          placeholder="Username"
          class="search-username"
          v-model="usernameSearch"
        >
          <template #prepend-content><CIcon name="cil-user" /></template>
        </CInput>

        <CButton @click="filterUsers" color="facebook" class="filter-button">
          <CIcon name="cil-settings" />&nbsp;Filter
        </CButton>
      </div>
    </vue-good-table>
  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';
import { mapActions, mapGetters, mapState } from 'vuex';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import {} from '@/services/notification.service';

export default {
  data() {
    return {
      columns: [
        {
          label: 'Sender Name',
          field: 'sender',
        },
        {
          label: 'User Name',
          field: 'user_name',
        },
        {
          label: 'Message',
          field: 'message',
        },
        {
          label: 'Time Created',
          field: 'created_at',
        },
        {
          label: 'Time Updated',
          field: 'updated_at',
        },
        {
          label: 'Units Charged',
          field: 'total_units_charged',
        },
      ],

      selectedDate: {
        start: '',
        end: '',
      },
      usernameSearch: '',
    };
  },

  notifications: {
    futureDateError: {
      title: 'Failed',
      message: 'You can not search future Data! Please adjust your End Date ',
      type: 'warn',
    },
    startDateError: {
      title: 'Failed',
      message: 'Please pick a start date',
      type: 'warn',
    },
    stopDateError: {
      title: 'Failed',
      message: 'Please pick a stop date',
      type: 'warn',
    },
    dateRangeError: {
      title: 'Failed',
      message: 'Please enter a date range',
      type: 'error',
    },
    noSelectedUserRoleError: {
      title: 'Failed',
      message: 'Please Select A User Role',
      type: 'error',
    },
    updateSuccess: {
      title: 'Success',
      message: `User has been upgraded!`,
      type: 'success',
    },
    updateFailed: {
      title: 'Failed',
      message:
        'Update failed at this time, kindly check your network and try again!',
      type: 'error',
    },
  },

  components: {
    VueGoodTable,
  },

  created() {
    this.fetchOutbox();
  },

  computed: {
    ...mapGetters('admin', ['outbox', 'loading', 'serverResponse']),
    ...mapState('coreuiStore', ['darkMode']),
  },

  methods: {
    ...mapActions('admin', ['fetchOutbox']),

    isDarkMode() {
      return this.darkMode ? 'nocturnal' : null;
    },

    formatedData() {
      const dayJs = dayjs.extend(advancedFormat);
      return this.outbox.outbox.map((names) => ({
        ...names,
        created_at: dayJs(names.created_at).format('Do MMM YYYY'),
        updated_at: dayJs(names.updated_at).format('Do MMM YYYY'),
      }));
    },

    filterUsers() {
      const startDate = dayjs(this.selectedDate.start).format('YYYY-MM-DD');
      const stopDate = dayjs(this.selectedDate.end).format('YYYY-MM-DD');
      const isAfterToday = dayjs(stopDate).isAfter(dayjs());

      if (
        (this.selectedDate.start == '' && this.selectedDate.end == '') ||
        (startDate == 'Invalid Date' && stopDate == 'Invalid Date')
      ) {
        this.fetchOutbox();
        return this.dateRangeError();
      }

      if (startDate == 'Invalid Date') return this.startDateError();

      if (stopDate == 'Invalid Date') return this.stopDateError();

      if (isAfterToday) return this.futureDateError();

      const searchQuery =
        this.usernameSearch == ''
          ? `?start-date=${startDate}&stop-date=${stopDate}`
          : `?start-date=${startDate}&stop-date=${stopDate}&username=${this.usernameSearch}`;

      this.fetchOutbox(searchQuery);
    },
  },
};
</script>

<style scoped>
.table-actions-container {
  display: flex;
  font-weight: bold;
  font-size: 1.1rem;
  align-items: center;
  margin-bottom: -1.1rem;
  flex-wrap: wrap;
}
.filter-button {
  margin-bottom: 1rem;
}
.search-username {
  margin-right: 1rem;
}

@media (min-width: 345px) {
  .search-username {
    margin-top: 0.5rem;
    margin: 0;
  }
  .filter-button {
    margin-top: 1rem;
  }
}
@media (min-width: 400px) {
  .stop-date {
    margin-left: 5px;
  }
  .filter-button {
    margin-left: 1rem;
    margin-bottom: 2rem;
  }
}

@media (min-width: 400px) {
  .search-username {
    margin-bottom: 10px;
  }
}

@media (min-width: 624px) {
  .search-username {
    margin-left: 5px;
  }
}

@media (min-width: 629px) {
  .search-username {
    margin-left: 5px;
    margin-top: 1.8rem;
  }
  .filter-button {
    margin-top: 0;
  }
}

@media (min-width: 720px) {
  .filter-button {
    margin-right: 1rem;
  }
}

@media (min-width: 736px) {
  .filter-button {
    margin-top: 2rem;
    margin-right: 1rem;
    margin-bottom: 1.2rem;
  }
  .search-username {
    margin-inline: 1rem;
    margin-top: 1.5rem;
  }
}

@media (min-width: 763px) {
  .filter-button {
    margin-top: 2rem;
  }
}

@media (min-width: 768px) {
  .filter-button {
    margin-top: 0;
  }
}

@media (min-width: 793px) {
  .filter-button {
    margin-top: 2rem;
  }
}
@media (min-width: 992px) {
  .filter-button {
    margin-top: 0;
  }
}
@media (min-width: 1049px) {
  .filter-button {
    margin-top: 2rem;
  }
}
</style>
